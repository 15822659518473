<template>
    <footer class="footer has-cards">
        <div class="container">
            <hr>
            <b-row>
                <b-col class="text-center">
                    <h2>Вопросы или комментарии?</h2><br>
                </b-col>
            </b-row>

            <!-- Desktop container -->
            <hide-at breakpoint="mediumAndBelow">
                <b-row>
                    <b-col>
                        <contact-form />
                    </b-col>

                    <b-col>
                        <p>Если у вас есть вопросы, пожелания или замечания, пожалуйста, пишите нам.</p>
                        <p>Редактор гимнов: Юрий Бойко</p>
                        <p><font-awesome-icon icon="phone-alt" /> +1-509-487-1319</p>
                        <p><font-awesome-icon icon="mobile-alt" class="mobile-space" /> +1-509-499-8680</p>
                        <p><font-awesome-icon icon="envelope" /> <b-link class="env" href="mailto:info@windorchestra.net">info@windorchestra.net</b-link></p>
                    </b-col>
                </b-row>
            </hide-at>
            <!-- Desktop container -->

            <!-- Mobile container -->
            <show-at breakpoint="mediumAndBelow">
                <b-row>
                    <b-col>
                        <contact-form />
                        <hr>
                        <p>Редактор гимнов: Юрий Бойко</p>
                        <p><font-awesome-icon icon="phone-alt" /> +1-509-487-1319</p>
                        <p><font-awesome-icon icon="mobile-alt" class="mobile-space" /> +1-509-499-8680</p>
                        <p><font-awesome-icon icon="envelope" /> <b-link class="env" href="mailto:info@windorchestra.net">info@windorchestra.net</b-link></p>
                    </b-col>
                </b-row>
            </show-at>
            <!-- Mobile container -->

            <b-row>
                <b-col>
                    <div class="copyright text-center">
                        <br><br>
                        &copy; {{ currentYear }} Windorchestra | 
                        <b-link><router-link to="/links">Ссылки</router-link></b-link> | 
                        <b-link><router-link to="/programs">Программы</router-link></b-link>
                    </div>
                </b-col>
            </b-row>
        </div>
    </footer>
</template>
<script>
import ContactForm from "./components/ContactForm";
import {showAt, hideAt} from 'vue-breakpoints';

export default {
    name: "AppFooter",
    components: {
        ContactForm,
        showAt,
        hideAt
    },
    data () {
        return {
            currentYear: null
        };
    },
    beforeMount() {
        this.getCurrentYear();
    },
    methods: {
        getCurrentYear() {
            const dt = new Date();
            this.currentYear = dt.getFullYear();
        }
    }
};
</script>
<style scoped>
    .env {
        padding-left: 8px;
    }
    .mobile-space {
        margin-right: 7px;
    }
</style>
